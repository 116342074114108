<template>
  <div style="margin-top: 20px">
    <el-card :body-style="{ paddingBottom: 0 }">
      <form-create
        v-model="where"
        :components="[
          {
            name: 'form-item',
            props: {
              prop: 'keyword',
              label: '课程名称',
              style: { width: '550px' },
              labelWidth: '80px',
            },
          },
        ]"
        inline
      >
        <el-form-item class="operate" slot="after">
          <el-button
            type="primary"
            size="small"
            @click="$refs.table.reset(where)"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="$refs.table.reset((where = {}))"
            >重置</el-button
          >
        </el-form-item>
      </form-create>
    </el-card>

    <el-card
      style="margin-top: 15px"
      :body-style="{ padding: '20px 10px' }"
      class="table-box"
    >
      <com-table
        ref="table"
        :data="{
          url: '/xapi/course.course/myApplyList',
          params: { ...$route.params, ...$route.query },
        }"
        :columns="[]"
        :page-size="12"
        :where="where"
        :row-page="6"
        list-type="table-list"
        @done="selectData = []"
      >
        <div style="padding: 0 10px; margin-bottom: 15px" slot-scope="{ row }">
          <el-card
            :body-style="{ padding: 0 }"
            :style="{ borderRadius: '15px 15px 0 0', width: '100%' }"
          >
            

            <div style="width: 100%; height: 160px; position: relative">
              <!-- <el-image
                    :src="row.cover"
                    style="width:100%;height:10rem"
                    @click="$handleRoute({course_id:row.id} , 'teachingCourseDetail')"
                >
                </el-image> -->
              <el-image
                :src="row.cover"
                style="width: 100%; height: 10rem"
                @click="goDetails(row)"
              >
              </el-image>
              <!-- <div style="width:100%;height:10rem" @click="$handleRoute({course_id:row.id} , 'teachingCourseDetail')">
                  <img style="width:100%;height:100%" :src="row.cover" alt="">
                </div> -->
            </div>
            <div
              class="shenluehao-2"
              style="display: flex; align-items: center; padding: 4px 14px"
            >
              <!-- <el-link :disabled="!$isPowers('courseEdit')" @click="$handleRoute({course_id:row.id} , 'teachingCourseEdit')"> -->
              <!-- <el-link
                :disabled="!$isPowers('courseEdit')"
                @click="
                  $handleRoute({ course_id: row.id }, 'teachingCourseEditNew')
                "
              >
              </el-link> -->
              <!-- <span class="title1" @click="$handleRoute({course_id:row.id} , 'teachingCourseDetail')">{{ row.course_name }}</span> -->
              <span class="title1" @click="goDetails(row)">{{
                row.course_name
              }}</span>
            </div>
            <div class="time">创建时间：{{ row.create_time }}</div>
            <div
              :style="{
                padding: '8px 14px',
                borderBottom: '1px solid #e6e6e6',
              }"
            >
              <div
                :style="{
                  fontSize: '90%',
                  lineHeight: 1.5,
                  color: 'rgba(254,81,52,1)',
                }"
              >
                {{ row.price > 0 ? "¥" + row.price : "免费" }}
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;
                  height: 40px;
                "
              >
                <div
                  :style="{
                    marginTop: '0',
                    fontSize: '90%',
                    lineHeight: 1.5,
                    color: 'rgb(136, 136, 136)',
                  }"
                >
                  {{ row.cid_name }} · {{ row.people || 0 }} 人报名
                </div>
                <div v-if="row.learn_mode == 2">
                  <!-- <el-button size="mini" type="primary" >出勤</el-button> -->
                  <!-- <el-button
                    size="mini"
                    type="primary"
                    :disabled="row.is_up != 1"
                    @click="checkTotal(row)"
                    >统计</el-button
                  > -->
                </div>
              </div>
            </div>
            <div :style="{ padding: '8px 14px' }">
              <div style="display: flex; line-height: 40px">
                <!-- <el-avatar v-if="row.lecturer" style="width: 40px;height: 40px;" :src="row.lecturer.headimg"></el-avatar> -->
                <el-image
                  v-if="row.lecturer"
                  style="width: 40px; height: 40px; border-radius: 50%"
                  :src="row.lecturer.headimg"
                  fit="cover"
                ></el-image>

                <div
                  style="margin-left: 5px; font-weight: 700; flex: 1"
                  class="shenluehao"
                >
                  {{ row.teach_name }}
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </com-table>
    </el-card>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      where: {},
      selectData: [],
      // userinfo:JSON.parse(localStorage.getItem(''))
      // value:0
    };
  },
  created() {
    localStorage.removeItem("pageSize");
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          if(this.$refs.table){
            this.$refs.table.reset(this.where)
          }
        }
      });
    });
  },
  methods: {
    routeUpdate() {
      console.log("初始化");
      console.log(this.$attrs.folderData, "$attrs.folderData");
      this.selectData = [];
      this.$refs.table && this.$refs.table.reset();
      this.$parent.activeType = "t_course";
      this.$parent.powerName = "course";
    },
    checkTotal(row) {
      this.$handleRoute(
        { id: row.id },
        "/course/questOther/totalDetail",
        "_blank"
      );
    },
    changeTeam(row) {
      console.log(row, "rowrow");
      // return
      // if(this.value==0){
      this.$confirm("你确定创建团队吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/xapi/course.course/createGroup", { course_id: row.id })
            .then((res) => {
              console.log(res, "essssssssssss");
              this.$message({
                type: "success",
                message: "创建成功",
              });
            });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "已取消创建",
          });
        });
      // }
    },
    deleteFolder() {
      this.$confirm("你确定删除该文件夹吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/xapi/com.folder/delete", {
              id: (this.$attrs.folderData || {}).id,
            })
            .then((res) => {
              console.log(res, "你确定删除该文件夹吗");
              this.$message({
                type: "success",
                message: res.msg,
              });
              // this.getList()
              // this.routeUpdate()
              this.$handleRoute({ folder_id: 0 }, true);
            });
        })
        .catch((e) => {
          console.log(e, "eeeeeee");
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // getList(){
    //   this.$http.post('/xapi/com.folder/list' , {type:'t_course',pageSize:100})
    //     .then(res=>{

    //     })
    // },
    goDetails(row) {
      if (row.is_up != 1)
        return this.$message.error("该课程已下架,请联系该课程老师！");

      this.$handleRoute({ course_id: row.id }, "teachingCourseDetailNew");
    },
    remove() {
      this.$confirm("你确定将选中课程的文件夹移除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/xapi/course.course/removeFolder", {
              ids: this.selectData.map((d) => d.id),
              folder_id: (this.$attrs.folderData || {}).id || 0,
            })
            .then((res) => {
              // console.log(res,"rsssssssss");
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.routeUpdate();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消移除",
          });
        });
    },
    handleEditChange(row, { value, oldValue, prop }, confirm, confirm_title) {
      if (confirm && confirm_title) {
        console.log(222);
        this.$handleConfirm(
          confirm,
          "/xapi/course.course/updateField",
          { field: prop, id: row.id, value: value },
          {
            title: confirm_title,
            callback: () => {
              row[prop] = oldValue;
            },
          }
        );
      } else {
        console.log(111);
        this.$http
          .post("/xapi/course.course/updateField", {
            field: prop,
            id: row.id,
            value: value,
          })
          .then()
          .catch(() => {
            row[prop] = oldValue;
          });
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/ .table-box {
  .el-table .el-table__cell.table-cell-image,
  .el-table .el-table__cell.table-cell-image,
  .el-table .el-table__cell.table-cell-image.is-center {
    padding: 0 !important;
  }
  .el-checkbox__input .el-checkbox__inner {
    width: 20px;
    height: 20px;
  }
  .el-checkbox__input .el-checkbox__inner::after {
    border-width: 2px;
    height: 10px;
    left: 7px;
    top: 2px;
    width: 4px;
  }
}
.title1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
}
.time {
  font-size: 0.8rem;
  box-sizing: border-box;
  padding: 0 14px;
  font-weight: 400;
  color: #646464;
  line-height: 1.5rem;
}
</style>